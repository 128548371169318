<template>
  <div>
    <b-card
      no-body
      :header="name"
      v-for="(obj, name, indexx) in responseData"
      :key="indexx + 'client'"
    >
      <b-card no-body>
        <button
          type="button"
          @click="onCopy(indexx)"
          v-clipboard:error="onError"
        >
          Copy!
        </button>
        <b-list-group flush>
          <b-list-group-item
            v-for="(assetObj, assetName, assetIndex) in obj"
            :key="assetIndex + 'asset'"
          >
            <pre>

{{ assetName }}
NAME: {{ assetObj.security.name }}
ISIN: {{ assetObj.security.isin }}

ACC. NR.		AMOUNT/FACE
</pre
            >
            <pre
              class="m-0"
              v-for="(assetObj2, assetName2, assetIndex2) in assetObj.accounts"
              :key="assetIndex2 + 'asset'"
              >{{ assetName2 }}		{{ assetObj2.quantity }}
</pre
            >
            <pre>
TOTAL: 			{{
                Object.values(assetObj.accounts).reduce(function (s, a) {
                  return s + a.quantity;
                }, 0)
              }}
</pre
            >
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "WizardComponent5",
  data() {
    return {
      message: "",
      message1: "",
      message2: "",
      message3: "",
      message4: "",
      copiedText: "",
      resp: {
        CAI: {
          "INTC US Equity": {
            accounts: {
              "060-4560": {
                client_exposure: 0.02,
                portfolio_exposure: 0.05,
                quantity: 10210,
              },
              "063-1530": {
                client_exposure: 0.03,
                portfolio_exposure: 0.05,
                quantity: 588,
              },
              "130-2330": {
                client_exposure: 0.01,
                portfolio_exposure: 0.05,
                quantity: 252,
              },
            },
            security: {
              isin: "US4581401001",
              name: "INTEL CORP",
              ticker: "INTC US Equity",
            },
          },
          "NVDA US Equity": {
            accounts: {
              "060-4560": {
                client_exposure: 0.02,
                portfolio_exposure: 0.05,
                quantity: 1740,
              },
              "063-1530": {
                client_exposure: 0.03,
                portfolio_exposure: 0.05,
                quantity: 100,
              },
              "130-2330": {
                client_exposure: 0.01,
                portfolio_exposure: 0.05,
                quantity: 42,
              },
            },
            security: {
              isin: "US67066G1040",
              name: "NVIDIA CORP",
              ticker: "NVDA US Equity",
            },
          },
          "QCOM US Equity": {
            accounts: {
              "060-4560": {
                client_exposure: 0.02,
                portfolio_exposure: 0.05,
                quantity: 7165,
              },
              "063-1530": {
                client_exposure: 0.03,
                portfolio_exposure: 0.05,
                quantity: 412,
              },
              "130-2330": {
                client_exposure: 0.01,
                portfolio_exposure: 0.05,
                quantity: 176,
              },
            },
            security: {
              isin: "US7475251036",
              name: "QUALCOMM INC",
              ticker: "QCOM US Equity",
            },
          },
        },
        UBS: {
          "INTC US Equity": {
            accounts: {
              "240-105100": {
                client_exposure: 0.02,
                portfolio_exposure: 0.05,
                quantity: 10210,
              },
              "279-144837": {
                client_exposure: 0.05,
                portfolio_exposure: 0.05,
                quantity: 4008,
              },
              "279-144847": {
                client_exposure: 0.05,
                portfolio_exposure: 0.05,
                quantity: 3476,
              },
              "279-347728": {
                client_exposure: 0.05,
                portfolio_exposure: 0.05,
                quantity: 2095,
              },
            },
            security: {
              isin: "US4581401001",
              name: "INTEL CORP",
              ticker: "INTC US Equity",
            },
          },
          "NVDA US Equity": {
            accounts: {
              "240-105100": {
                client_exposure: 0.02,
                portfolio_exposure: 0.05,
                quantity: 1740,
              },
              "279-144837": {
                client_exposure: 0.05,
                portfolio_exposure: 0.05,
                quantity: 683,
              },
              "279-144847": {
                client_exposure: 0.05,
                portfolio_exposure: 0.05,
                quantity: 592,
              },
              "279-347728": {
                client_exposure: 0.05,
                portfolio_exposure: 0.05,
                quantity: 357,
              },
            },
            security: {
              isin: "US67066G1040",
              name: "NVIDIA CORP",
              ticker: "NVDA US Equity",
            },
          },
          "QCOM US Equity": {
            accounts: {
              "240-105100": {
                client_exposure: 0.02,
                portfolio_exposure: 0.05,
                quantity: 7165,
              },
              "279-144837": {
                client_exposure: 0.05,
                portfolio_exposure: 0.05,
                quantity: 2813,
              },
              "279-144847": {
                client_exposure: 0.05,
                portfolio_exposure: 0.05,
                quantity: 2439,
              },
              "279-347728": {
                client_exposure: 0.05,
                portfolio_exposure: 0.05,
                quantity: 1470,
              },
            },
            security: {
              isin: "US7475251036",
              name: "QUALCOMM INC",
              ticker: "QCOM US Equity",
            },
          },
        },
      },
    };
  },
  methods: {
    makeToast() {
      this.$bvToast.toast("Copied!", {
        title: `Status`,
        variant: "success",
        solid: true,
        toaster: "b-toaster-top-center",
      });
    },
    sum() {
      let element = document.getElementsByClassName(
        "list-group list-group-flush"
      );
    },
    onCopy: function (indexx) {
      let element = document.getElementsByClassName(
        "list-group list-group-flush"
      );
      let elementText = Array.from(element[indexx].children).reduce(
        (sum, value) => sum + value.textContent,
        ""
      );
      this.$copyText(elementText);
      this.makeToast();
    },
    onError: function (e) {},
  },
  computed: {
    responseData() {
      return this.$store.state.form_response; //This is the data displayed on form5(response from beeceptor)
    },
  },
};
</script>

<style scoped lang="css">
div {
  font-family: "Courier New", Courier, monospace;
}
.table {
  border: hidden;
}
.list-group-item {
  border: 0px solid rgba(0, 0, 0, 0.125);
}
</style>
